import React, { useEffect, Fragment, useState } from "react";
import CTA from "../../general/cta";
import { scrollToElement } from "../../../../utility/utils";
import EntryFold from "../../general/entryFold";
import About1 from "../../../../../assets/img/blur/kuda-new-about.inline.svg";
import EricaImage from "../../../../../assets/img/erica.jpg";
import Play from "../../../../../assets/img/play.inline.svg";
import AboutErica from "./sections/aboutErica";
import Illustration from "../../../../../assets/img/kuda-new-about.png";
import Panel from "./component/panel";
import { Img } from "react-image";
import About2 from "../../../../../assets/img/blur/erica-blur.inline.svg";
import ShareWithFriends from "../../general/shareWithFriends";

const questions = [
    {
        q: "What's your full name?",
        a: "Erica Ngozi Nlewedim."
    },
    {
        q: "What's your favourite colour?",
        a: "Gold"
    },
    {
        q: "What's your favourite app?",
        a: "Kuda."
    },
    {
        q: "Which social media do you like best?",
        a: "Instagram"
    },
    {
        q: "What are your superpowers?",
        a: "Determination and Persistence"
    },
    {
        q: "If you could eat just one meal for the rest of your life, what would it be?",
        a: "White rice and stew"
    },
    {
        q: "Do you own a pet?",
        a: "Yes, a dog called Baby."
    },
    {
        q: "What's your favourite country?",
        a: "Spain"
    },
    {
        q: "If you had a million dollars, what would you do with it?",
        a: "Invest, go on a vacation, give to charity and organise a skill acquisition program for my fans."
    },
    {
        q: "What’s your number one money rule?",
        a: "Invest before spending."
    }, {
        q: "What are your favourite fashion items?",
        a: "Bags, jewellery and glasses"
    },
    {
        q: "Do you swim?",
        a: "Yes, I do."
    },
    {
        q: "How many hours do you sleep for?",
        a: "7 hours"
    },
    {
        q: "What's your favourite holiday destination?",
        a: "Greece and Kenya"
    },
]

const entryContent = {
    title: "Hey, Elites!",
    subtitle: "Join me on Kuda now and enjoy 25 free transfers to other banks, a free debit card and more benefits from the bank of the free!",
    // illustration: Illustration
    illustration: <Img src={Illustration}/>

}

const Erica = ({ showModal }) => {
    const [state, setState] = useState({
        activeTab: 0
    })


    const activateTab = (index) => {
        setState((prevState) => ({
            ...prevState,
            activeTab: prevState.activeTab === index ? -1 : index
        }));
    }

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])


    const { activeTab } = state;

    return (
        <Fragment>
            <div className="kuda-inner--heading kuda-disclaimer--section text-center">
                <div className="kuda-section--inner">
                    <div className="kuda-section--100">
                        <h1 className="kuda-section--heading text-xl text-xlbold color-primary title-bottom--spacing">
                            Kuda x Erica
                        </h1>
                        <p className="card-main--heading text-semi-bold color-black ">Bank free with Erica!</p>
                    </div>
                </div>
            </div>
            <div className="kuda-ambassador--spotlight kuda-section--100" onClick={() => showModal()}>
                <div className="kuda-ambassador">
                    <Img alt="kuda Microfinance Bank Ambassador Erica"
                        src={EricaImage}
                        loader={<About2 className="blur kuda-ambassador-single" />} className="kuda-ambassador-single"
                    />
                    <div className="kuda-play">
                        <Play />
                    </div>
                </div>
            </div>

            <EntryFold title={entryContent.title} subtitle={entryContent.subtitle} illustration={entryContent.illustration} isImage={true} fallback={<About1 className="blur isImage" />} url="https://kuda.onelink.me/abUI/erica" />

            <AboutErica />

            <div className="kuda-section--100 kuda-spacing--inner kuda-standard--section">
                <h1 className="kuda-section--heading text-center text-xlbold color-primary title-bottom--spacing">
                    Fun facts about Erica
                        </h1>
                <div className='accordion mt-5' role='tablist'>
                    {questions.map((panel, index) =>
                        <Panel
                            key={index}
                            activeTab={activeTab}
                            index={index}
                            {...panel}
                            activateTab={() => activateTab(index)}
                        />
                    )}
                </div>

            </div>
            <ShareWithFriends />
            <CTA />

        </Fragment>
    )
}

export default Erica;
