import React from "react";
import ShareCard from "../../../general/shareCard";
import Step from "../../../general/step";
import TitleButton from "../../../general/titleButton";
import ShareErica from "../../../../../../assets/img/shareErica.png"
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import { copyTextToClipboard } from "../../../../../../util/util";

const share = {
    subheading: "Erica’s Referral Code",
    heading: "STARGIRL",
    image: ShareErica
}

const AboutErica = () => {
    const copy = () => {
        copyTextToClipboard("STARGIRL");
        toaster.notify("Referral Code Copied", {
            duration: 5000,
            position: 'bottom'
        });
    }

    return (
        <div className="kuda-section kuda-standard--section kuda-story--section">
            <div className="kuda-section--inner">
                <div className="kuda-section--100">
                    <h1 className="kuda-section--heading text-center text-xlbold color-primary title-bottom--spacing">
                        Steps To Open Your Kuda Account
                        </h1>
                    <div className="text-center justify-center flex flex-column align-center kuda-story--wrap mt-5">
                        <div className="kuda-steps--account kuda-100">
                            <Step count={1} />
                            <TitleButton button="Download Kuda" url="https://kuda.onelink.me/abUI/erica" isExternal={true} />
                        </div>

                        <div className="kuda-steps--account">
                            <Step count={2} />
                            <div className="kuda-main--wrap flex flex-column align-center mt-0">
                                <p className="card-main--heading color-black">Open a Kuda account with my referral code: <span className="color-primary text-xbold">STARGIRL</span></p>
                            </div>
                            <ShareCard subheading={share.subheading} heading={share.heading} image={share.image} copy={copy} />
                        </div>

                        <div className="kuda-steps--account">
                            <Step count={3} />
                            <TitleButton text={<span className="text-normal">Share your referral code and get <span className="text-xbold color-primary">200 naira</span> for everybody who opens a Kuda account with your code!</span>} button="Download Kuda" url="https://kuda.onelink.me/abUI/erica" isExternal={true} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AboutErica;
