import React from "react";
import Social from "./social.js";

const ShareWithFriends = () => {
    return (
        <div className="kuda-section">
            <div className="kuda-entry--inner kuda-cta--section flex justify-between align-center primary-cta flex-column justify-center blue-cta">
                <div className="bottom-spacer--lg">
                    <h1 className="kuda-section--heading text-xlbold color-white title-spacing text-center ">
                        Share this with your friends
                    </h1>
                </div>
                <div className="kuda-section--1000">
                    <Social />
                </div>

            </div>
        </div>
    )
}

export default ShareWithFriends;
