import React from "react";
import Share from "../../../../assets/img/copyIcon.inline.svg";
import { Img } from "react-image";

const ShareCard = (props) => {

    return (
        <div className={`kuda-share--card flex align-center justify-between cursor-pointer ${typeof props.className !== "undefined" ? props.className : ""}`} onClick={props.copy}>
            <div className="single-share--info flex align-center">
                {typeof props.image !== "undefined" &&
                    <div className="share-card--image">
                        <Img src={props.image} alt="Erica Kuda Microfinance Bank" />
                    </div>
                }
                <div className="share-info-text">
                    <p className="share-small--text text-bold color-primary text-sm">{props.subheading}</p>
                    <p className="share-small--text text-xlbold color-primary">{props.heading}</p>
                </div>
            </div>
            <div className="share-trigger flex flex-column justify-center align-center">
                <Share />
                <p className="mt-1 text-sm">Copy</p>
            </div>
        </div>
    )
}

export default React.memo(ShareCard);
