import React from "react"
import Facebook from "../../../../assets/img/social/facebook.inline.svg"
import Twitter from "../../../../assets/img/social/twitter.inline.svg"
import Link from "../../../../assets/img/social/link.inline.svg"
import BlueLink from "../../../../assets/img/march/blueCopy.inline.svg"
import BlueMessage from "../../../../assets/img/march/blueMessage.inline.svg"
import Whatsapp from "../../../../assets/img/social/whatsapp.inline.svg"
import Message from "../../../../assets/img/social/message.inline.svg"
import toaster from "toasted-notes"
import { copyTextToClipboard } from "../../../../util/util"

const Social = ({ bgISWhite }) => {
  const socials = [
    {
      icon: <Twitter />,
      url:
        "https://twitter.com/intent/tweet?url=https%3A%2F%2Fkuda.com%2Ferica&text=Join%20Erica%20on%20Kuda%20and%20enjoy%2025%20free%20transfers%20to%20other%20banks%2C%20a%20free%20debit%20card%20and%20more%20benefits%20from%20the%20bank%20of%20the%20free%21",
      name: "twitter",
    },
    {
      icon: <Facebook />,
      url:
        "https://www.facebook.com/sharer/sharer.php?u=https://kuda.com/erica/",
      name: "facebook",
    },
    {
      icon: bgISWhite ? <BlueLink /> : <Link />,
      url: "https://kuda.com/erica/",
      name: "link",
    },
    {
      icon: <Whatsapp />,
      url:
        "https://api.whatsapp.com/send?text=Join Erica on Kuda and enjoy 25 free transfers to other banks, a free debit card and more benefits from the bank of the free!: https://kuda.com/erica",
      name: "twitter",
    },
    {
      icon: bgISWhite ? <BlueMessage /> : <Message />,
      url:
        "mailto:info@example.com?&subject=&body=https://kuda.com/erica 'Join Erica on Kuda and enjoy 25 free transfers to other banks, a free debit card and more benefits from the bank of the free!: https://kuda.com/erica",
      name: "message",
    },
  ]

  const copy = url => {
    copyTextToClipboard(url)
    toaster.notify("Share Link Copied", {
      duration: 1000,
      position: "bottom",
    })
  }
  return (
    <div className="kuda-social--wrap">
      <ul className="kuda-social--li flex">
        {socials.map((item, index) => (
          <li key={index}>
            {item.name === "link" ? (
              <span
                href={item.url}
                className="flex cursor-copy"
                onClick={() => copy(item.url)}
              >
                {item.icon}
              </span>
            ) : (
              <a
                href={item.url}
                className="flex"
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                {item.icon}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Social
