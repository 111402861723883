import React, { useState } from "react"
import EricaPage from "../components/body/pages/ambassadors/erica"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ModalVideo from "react-modal-video"
import { Fragment } from "react"

const Erica = () => {
  const [isOpen, setOpen] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  return (
    <Fragment>
      <Layout>
        <SEO
          canonical={"https://kuda.com/erica/"}
          title="Kuda x Erica | The Money App for Africans"
          description="Join the money app for Africans and bank with Erica, the first-ever Kuda ambassador!"
        />
        <EricaPage showModal={showModal} />
      </Layout>
      <ModalVideo
        channel="youtube"
        autoplay
        modestbranding={1}
        controls={0}
        isOpen={isOpen}
        videoId="qq6kVCz9sH0"
        onClose={() => setOpen(false)}
      />
    </Fragment>
  )
}

export default Erica
