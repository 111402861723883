import React from "react";

const Step = (props) => {
    return (
        <div className={`kuda-app--step flex align-center justify-center ${typeof props.className !== "undefined" ? props.className : ""}`}>
            {props.count}
        </div>
    )
}
export default Step;
